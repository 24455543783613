.App {
  text-align: center; }

$main-color: #5500aa;

html {
  scroll-behavior: smooth;
  overflow-x: hidden; }

body {
  font-family: 'Titillium Web', sans-serif; }

.gallery-wrapper {
  position: absolute;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  width: 100vw;
  height: 100vh; }

.gallery {
  position: relative;
  width: 100%;
  height: 100%; }

.email {
  font-size: 20px; }

.title {
  text-decoration: underline;
  text-decoration-color: $main-color; }

.presentation-section {
  margin-top: 40px !important; }

.navbar {
  border-bottom: 2px solid black;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1; }

.link {
  height: 100%;
  display: flex;
  position: relative;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
  transition: 0.2s;
  hr {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    border: 0;
    background-color: black;
    transition: 0.2s; }
  &:hover {
    hr {
      border-bottom: 5px solid $main-color; } } }

.profile-picture {
  outline: 5px solid black;
  padding: 5px;
  width: 230px; }

.btn {
  background-color: white;
  border-radius: 0px;
  border: 5px solid black;
  padding: 7px;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  transition: 0.3s;
  &:hover {
    cursor: pointer; }
  &:focus {
    outline: none; } }

.btn-large {
  min-width: 277px; }

.btn-outlined {
  &:after {
    content: "";
    outline: 5px solid $main-color;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    transition: 0.2s; }
  &:hover {
    &:after {
      margin: 0 0 -7px 7px; } } }

.btn-a {}

.btn-disabled {
  cursor: default !important;
  user-select: none; }

@media only screen and (min-width: 769px) {
  .presentation-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 75px 75px 100px;
    column-gap: 90px;
    row-gap: 10px;
    * {
      align-self: self-start; }
    img {
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 4;
      align-self: flex-end; } } }

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .presentation-grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 75px auto 75px 75px;
    justify-items: center;
    column-gap: 20px;
    row-gap: 10px;
    * {
      align-self: self-start; }
    img {
      grid-row-start: 2;
      grid-row-end: 2; } } }

.divider {
  width: 100%;
 }  //border: 1px solid black

.skill {
  white-space: nowrap;
  width: 145px;
  display: flex;
  align-items: center;
  img {
    margin: 5px 7px;
    width: 40px;
    height: 40px;
    object-fit: contain; } }

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr); }

@for $i from 1 through 1000 {
  .row-gap-#{$i} {
    row-gap: #{$i}px; } }

@for $i from 1 through 1000 {
  .column-gap-#{$i} {
    column-gap: #{$i}px; } }

@for $i from 1 through 12 {
  .col-#{$i} {
    grid-column: span #{$i}; } }

@media only screen and (min-width: 0px) {
  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      grid-column: span #{$i}; } } }

@media only screen and (min-width: 768px) {
  @for $i from 1 through 12 {
    .col-md-#{$i} {
      grid-column: span #{$i}; } } }

@media only screen and (min-width: 992px) {
  @for $i from 1 through 12 {
    .col-lg-#{$i} {
      grid-column: span #{$i}; } } }

@media only screen and (min-width: 1480px) {
  @for $i from 1 through 12 {
    .col-xl-#{$i} {
      grid-column: span #{$i}; } } }

@media only screen and (min-width: 0px) {
  .project-container {
    border: 5px solid black;
    width: 300px;
    img {
      width: 300px;
      height: 300px;
      object-fit: cover; } }
  .technologies-grid {
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto; }
  .project-info-container {
    //grid-template-rows: 40px 50px auto 50px
    padding: 25px 20px 30px 20px;
    h2 {
      margin: 0; }
    h4 {
      margin: 0; } } }

@media only screen and (min-width: 768px) {
  .project-container {
    border: 5px solid black;
    width: 700px;
    height: 300px;
    img {
      width: 300px;
      height: 300px;
      object-fit: cover; } }
  .technologies-grid {
    grid-template-columns: auto auto auto auto auto auto; }
  .project-info-container {
    grid-template-rows: 40px 50px auto 50px;
    padding: 25px 20px 30px 20px;
    h2 {
      margin: 0; }
    h4 {
      margin: 0; } } }

.text-align-left {
  text-align: left; }

.justify-left {
  justify-self: start; }

.align-self-top {
  align-self: self-start; }

.align-self-center {
  align-self: center; }

.align-self-bottom {
  align-self: self-end; }

.width-fluid {
  width: 100%; }

.image {
  img {
    //padding: 7px
    box-sizing: border-box; } }

.project-skill {
  border: 5px solid black;
  padding: 3px;
  width: 35px !important;
  height: 35px !important;
  object-fit: contain !important; }

.social-link {
  width: 130px; }

.CV {
  margin-top: 10px; }

.CV_button {
  margin-top: 20px; }
